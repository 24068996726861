import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subject, Subscription, timer} from 'rxjs';
import {AuthService} from './services/auth.service';
import {take, takeUntil} from 'rxjs/operators';
import {ApiResponse} from './models/ApiResponse';
import {GeneralService} from './services/general.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('modalLogoutAlert') private modalLogoutAlert;
  title = 'Prominence Bank Corp.';
  idleCountDown = true;
  minutesDisplay = 0;
  secondsDisplay = 0;

  endTime = 2;
  countDown = 10;

  unsubscribe$: Subject<void> = new Subject();
  timerSubscription: Subscription;

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  resetTimer() {
    this.authService.notifyUserAction();
  }

  constructor(private router: Router, private authService: AuthService, private generalService: GeneralService) { }

  ngOnInit() {
    this.generalService.idleCountDownCheck.subscribe(val => {
      this.idleCountDown = val;
    });
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    // auto logut code test
    this.resetTimerRequest();
    this.authService.userActionOccured.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
      this.resetTimerRequest();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // resetTimer () {
  //   this.authService.notifyUserAction();
  // }
  resetTimerRequest(endTime: number = this.endTime) {
    console.log('resetTimerRequest');
    this.modalLogoutAlert.hide();
    const interval = 1000;
    const duration = endTime * 60;
    this.timerSubscription = timer(0, interval).pipe(
      take(duration)
    ).subscribe(value => {
      this.countDown = duration - +value;
      if (this.countDown === 10 && this.idleCountDown) {
        this.modalLogoutAlert.show();
      }
        this.render((duration - +value) * interval);
        // console.log('fires');
      },
      err => { },
      () => {
        if (this.idleCountDown) {
          this.modalLogoutAlert.hide();
          this.router.navigate(['logout']);
        }
      }
    );
  }

  private render(count) {
    this.secondsDisplay = this.getSeconds(count);
    this.minutesDisplay = this.getMinutes(count);
  }

  private getSeconds(ticks: number) {
    const seconds = ((ticks % 60000) / 1000).toFixed(0);
    return this.pad(seconds);
  }

  private getMinutes(ticks: number) {
    const minutes = Math.floor(ticks / 60000);
    return this.pad(minutes);
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }
}
