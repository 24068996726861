import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class AuthService {
  private localStorageAuthTokeKey = 'd6fe52872e0d';
  _userActionOccured: Subject<void> = new Subject();
  get userActionOccured(): Observable<void> { return this._userActionOccured.asObservable(); }

  constructor(private API: ApiService) {}

  notifyUserAction() {
    this._userActionOccured.next();
  }

  saveLocalStorageAuthToken(tokenObject: {}) {
    localStorage.setItem(this.localStorageAuthTokeKey, JSON.stringify(tokenObject));
  }

  getLocalStorageAuthToken() {
    const tokenObject = localStorage.getItem(this.localStorageAuthTokeKey);
    if (tokenObject === null) {
      return null;
    } else {
      return JSON.parse(tokenObject);
    }
  }

  removeLocalStorageAuthToken() {
    localStorage.removeItem(this.localStorageAuthTokeKey);
  }

  sendLoginFormData(username: string, password: string, captcha: string) {
    const data = {
      'username' : username,
      'password' : password,
      'captcha': captcha,
      'device_type' : 'web',
      'device_token' : 'web',
      'user_agent' : navigator.userAgent,
      'platform' : navigator.platform,
    };
    return this.API.post('auth/login', data);
  }

  sendRegisterFormData(full_name: string, email: string, password: string, sponsor: string, invitation: string) {
    const data = {
      'full_name' : full_name,
      'email' : email,
      'password' : password,
      'sponsor' : sponsor,
      'device_type' : 'web',
      'invitation' : invitation
    };
    return this.API.post('auth/register', data);
  }

  sendForgotPasswordFormData(email: string) {
    return this.API.post('password/forgot', {'email' : email});
  }

  sendResetPasswordFormData(token: string, email: string, password: string) {
    const data = {
      'token' : token,
      'email' : email,
      'password' : password,
    };
    return this.API.post('password/reset', data);
  }

  sentOtpFormData(otp: string) {
    return this.API.post('auth/verify_otp', {'otp' : otp});
  }

  isLoggedIn() {
    const tokenObj: {auth_token: string, otp?: boolean} = this.getLocalStorageAuthToken();

    if (!tokenObj || !tokenObj.otp || tokenObj === null) {
      return false;
    }
    this.API.saveAuthToken(tokenObj.auth_token);
    return true;
  }

  logout() {
    return this.API.post('auth/logout', {'all_devices': 0});
  }
}
