import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AdminRolesGuardService implements CanActivate {
  role: number;
  superGuardModules = [
    'dashboard',
    'users',
    'customers',
    'accounts',
    'deposits',
    'requests',
    'settings',
    'funds',
    'loan',
    'credit',
    'banking',
    'crypto',
    'regenerate',
    'admin',
  ];
  role5Modules = [
    'dashboard',
    'users',
    'accounts',
    'crypto',
    'banking',
    'settings',
  ];
  role6Modules = [
    'dashboard',
    'users',
    'customers',
    'accounts',
    'crypto',
    'banking',
    'settings',
  ];
  role7Modules = [
    'dashboard',
    'users',
    'accounts',
    'crypto',
    'deposits',
    'funds',
    'requests',
    'loan',
    'credit',
    'regenerate',
    'settings',
  ];

  constructor(private router: Router,
              private userService: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const urlSegments = state.url.toString().split('/');
    const module = urlSegments[1];
    this.role = +this.userService.role;

    if (this.role < 5) {
      const index = this.superGuardModules.findIndex(url => url === module);
      if (index > -1) {
        return true;
      } else {
        this.router.navigate(['/login']);
        return false;
      }
    } else if (this.role === 5) {
      const index = this.role5Modules.findIndex(url => url === module);
      if (index > -1) {
        if (this.onlyUrlAccessOfRole5(state.url, module, urlSegments) === true) {
          return true;
        } else {
          this.router.navigate(['/login']);
          return false;
        }
      } else {
        this.router.navigate(['/login']);
        return false;
      }
    } else if (this.role === 6) {
      const index = this.role6Modules.findIndex(url => url === module);
      if (index > -1) {
        if (this.onlyUrlAccessOfRole6(state.url, module, urlSegments) === true) {
          return true;
        } else {
          this.router.navigate(['/login']);
          return false;
        }
      } else {
        this.router.navigate(['/login']);
        return false;
      }
    } else if (this.role === 7) {
      const index = this.role7Modules.findIndex(url => url === module);
      if (index > -1) {
        if (this.onlyUrlAccessOfRole7(state.url, module, urlSegments) === true) {
          return true;
        } else {
          this.router.navigate(['/login']);
          return false;
        }
      } else {
        this.router.navigate(['/login']);
        return false;
      }
    } else {
      this.userService.emitRole.subscribe(role => {
        this.role = +role;
        return this.canActivate(route, state);
      });
    }
  }

  private onlyUrlAccessOfRole5(url, module, urlSegments) {
    if (module === 'dashboard') {
      if (url !== '/dashboard') {
        return false;
      }
      return true;
    } else if (module === 'accounts') {
      if (url !== '/accounts/all') {
        return false;
      }
      return true;
    } else if (module === 'crypto') {
      if (url !== '/crypto/all') {
        return false;
      }
      return true;
    } else if (module === 'banking') {
      if (url === '/banking/products/dc') {
        return true;
      } else if (url === '/banking/products/sblc') {
        return true;
      } else if (url === '/banking/products/bg') {
        return true;
      } else if (url === '/banking/products/skr') {
        return true;
      } else if (url === '/banking/products/bcc') {
        return true;
      } else if (url === '/banking/products/pof') {
        return true;
      } else if (url === '/banking/products/bf') {
        return true;
      } else if (url === '/banking/products/ktt') {
        return true;
      } else if (url === '/banking/products/swift') {
        return true;
      }
      return false;
    } else if (module === 'settings') {
      if (url !== '/settings/change/password') {
        return false;
      }
      return true;
    } else if (module === 'users') {
      return true;
    } else {
      return false;
    }
  }

  private onlyUrlAccessOfRole6(url, module, urlSegments) {
    if (module === 'dashboard') {
      return true;
    } else if (module === 'customers') {
      if (urlSegments[2] === 'login-logs') {
        return false;
      }
      return true;
    } else if (module === 'accounts') {
      if (url === '/accounts/hold_balance') {
        return false;
      }
      return true;
    } else if (module === 'crypto') {
      if (url === '/crypto/deposit') {
        return false;
      }
      return true;
    } else if (module === 'banking') {
      return true;
    } else if (module === 'settings') {
      if (url !== '/settings/change/password') {
        return false;
      }
      return true;
    } else if (module === 'users') {
      return true;
    } else {
      return false;
    }
  }

  private onlyUrlAccessOfRole7(url, module, urlSegments) {
    if (module === 'dashboard') {
      return true;
    } else if (module === 'accounts') {
      if (url === '/accounts/add') {
        return false;
      } else if (urlSegments[2] === 'edit') {
        return false;
      } else if (urlSegments[4] === 'edit') {
        return false;
      }
      return true;
    } else if (module === 'crypto') {
      if (url === '/crypto/add') {
        return false;
      } else if (urlSegments[2] === 'edit') {
        return false;
      }
      return true;
    } else if (module === 'deposits') {
      return true;
    } else if (module === 'funds') {
      return true;
    } else if (module === 'requests') {
      return true;
    } else if (module === 'loan') {
      return true;
    } else if (module === 'credit') {
      return true;
    } else if (module === 'regenerate') {
      return true;
    } else if (module === 'settings') {
      if (url !== '/settings/change/password') {
        return false;
      }
      return true;
    } else if (module === 'users') {
      return true;
    } else {
      return false;
    }
  }
}
