import {Injectable} from '@angular/core';
import {CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

import {AuthService} from './auth.service';
import {UserService} from './user.service';
import {GeneralService} from './general.service';
import {ApiResponse} from '../models/ApiResponse';


@Injectable()
export class AuthGuard implements CanActivate {
  isUserAuthenticated = false;
  state: RouterStateSnapshot;

  superGuardUrls = [
    'dashboard',
    'users',
    'customers',
    'accounts',
    'deposits',
    'requests',
    'settings',
    'funds',
    'loan',
    'credit',
    'banking',
    'crypto',
    'regenerate',
    'admin',
  ];
  role5Urls = [
    'dashboard',
    'users',
    'accounts',
    'crypto',
    'banking',
  ];
  role6Urls = [];
  role7Urls = [];

  constructor(private router: Router,
              private userService: UserService,
              private generalService: GeneralService,
              private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.state = state;
    if (this.authService.isLoggedIn()) {
      return this.validate().then((validate: boolean) => {
        return validate;
      }).catch((validate: boolean) => {
        return validate;
      });
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  validate() {
    return new Promise((resolve, reject) => {
      this.generalService.validate()
        .then((res: ApiResponse) => {
          if (res.status === true) {
            this.isUserAuthenticated = true;
            // some comment
            // this.generalService.sidebarMenuLabels = res.labels || {};
            // this.userService.userCurrentLang = res.current_language;
            // this.userService.userLang.emit(res.current_language);
            this.userService.fullName = res.data.admin.name;
            this.userService.avatar = res.data.admin.avatar;
            this.userService.role = res.data.admin.role_type;
            this.userService.emitRole.emit(res.data.admin.role_type);

            // const urlSegments = this.state.url.toString().split('/');
            // const module = urlSegments[1];
            // const boolean = this.urlAccess(module);
            // if (boolean === true) {
            //   resolve(true);
            // } else {
            //   resolve(false);
            // }
            resolve(true);
          }
          resolve(false);
        })
        .catch(error => {
          reject(false);
        });
    });
  }

  urlAccess(module) {
    if (+this.userService.role < 5) {
      console.log(+this.userService.role);
      const index = this.superGuardUrls.findIndex(url => url === module);
      if (index > -1) {
        return true;
      } else {
        this.router.navigate(['/login']);
        return false;
      }
    } else if (+this.userService.role === 5) {
      console.log(+this.userService.role);
      const index = this.role5Urls.findIndex(url => url === module);
      if (index > -1) {
        return true;
      } else {
        this.router.navigate(['/login']);
        return false;
      }
    } else if (+this.userService.role === 6) {
      console.log(+this.userService.role);
      const index = this.role6Urls.findIndex(url => url === module);
      if (index > -1) {
        return true;
      } else {
        this.router.navigate(['/login']);
        return false;
      }
    } else if (+this.userService.role === 7) {
      console.log(+this.userService.role);
      const index = this.role7Urls.findIndex(url => url === module);
      console.log(index);
      if (index > -1) {
        return true;
      } else {
        this.router.navigate(['/login']);
        return false;
      }
    } else {
      console.log(+this.userService.role);
      this.userService.emitRole.subscribe(role => {
        this.userService.role = role;
        return this.urlAccess(module);
      });
      // return true;
    }
  }
}
