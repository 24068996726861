import {Injectable} from '@angular/core';
import {UserService} from '../../services/user.service';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: '',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Dashboard',
        type: 'link',
        icon: 'fa fa-home'
      },

      {
        state: 'admin',
        short_label: 'A',
        name: 'Admins',
        type: 'sub',
        icon: 'fa fa-user',
        children: [
          {
            state: 'add',
            name: 'Add New'
          },
          {
            state: 'list',
            name: 'View All',
          },
        ]
      },

      {
        state: 'customers',
        short_label: 'C',
        name: 'Customers',
        type: 'sub',
        icon: 'fa fa-users',
        children: [
          {
            state: 'add',
            name: 'Add Personal Profile'
          },
          {
            state: 'business/add',
            name: 'Add Business Profile'
          },
          {
            state: 'list',
            name: 'View All',
          },
        ]
      },

      {
        state: 'accounts',
        short_label: 'A',
        name: 'Accounts',
        type: 'sub',
        icon: 'fa fa-bank',
        children: [
          {
            state: 'add',
            name: 'Add New'
          },
          {
            state: 'all',
            name: 'View All',
          },
          {
            state: 'debit/card',
            name: 'All Debit Cards',
          },
          {
            state: 'hold_balance',
            name: 'Hold Account Balance',
          },
        ]
      },

      {
        state: 'crypto',
        short_label: 'CA',
        name: 'Crypto Accounts',
        type: 'sub',
        icon: 'fa fa-btc',
        children: [
          {
            state: 'add',
            name: 'Add New'
          },
          {
            state: 'all',
            name: 'View All',
          },
          {
            state: 'deposit',
            name: 'Crypto Deposits',
          },
        ]
      },

      {
        state: 'deposits',
        short_label: 'D',
        name: 'Deposits',
        type: 'link',
        icon: 'fa fa-money'
      },

      {
        state: 'funds/transfer',
        short_label: 'FTF',
        name: 'Funds Transfer',
        type: 'sub',
        icon: 'fa fa-link',
        children: [
          {
            state: '',
            name: 'Wire/SEPA'
          },
          {
            state: 'cryptoo',
            name: 'Crypto',
          },
          {
            state: 'c2c',
            name: 'Crypto 2 Crypto',
          },
        ]
      },

      // {
      //   state: 'funds/transfer',
      //   short_label: 'FTF',
      //   name: 'Funds Transfer',
      //   type: 'link',
      //   icon: 'fa fa-link'
      // },

      {
        state: 'banking/products',
        short_label: 'BP',
        name: 'Banking Products',
        type: 'sub',
        icon: 'fa fa-list',
        children: [
          {
            state: 'dc',
            name: 'Certificate of Deposit'
          },
          {
            state: 'sblc',
            name: 'Standby Letter of Credit'
          },
          {
            state: 'bg',
            name: 'Bank Guarantee'
          },
          {
            state: 'skr',
            name: 'Safe Keeping Receipt'
          },
          {
            state: 'bcc',
            name: 'Bank Certify Checks'
          },
          {
            state: 'pof',
            name: 'Proof of Funds'
          },
          {
            state: 'bf',
            name: 'Block Funds'
          },
          {
            state: 'ktt',
            name: 'Key Tested Telex'
          },
          {
            state: 'swift',
            name: 'Swift'
          },
        ]
      },

      {
        state: 'requests',
        short_label: 'R',
        name: 'Requests',
        type: 'sub',
        icon: 'fa fa-tasks',
        children: [
          {
            state: 'cheque/block',
            name: 'Cheque Block'
          },
          {
            state: 'loan/application',
            name: 'Loan Application',
          },
          {
            state: 'credit/card',
            name: 'Credit Card',
          },
          {
            state: 'travel/notification',
            name: 'Travel Notification',
          },
        ]
      },

      {
        state: 'loan',
        short_label: 'L',
        name: 'Loan',
        type: 'link',
        icon: 'fa fa-money'
      },

      {
        state: 'credit/card',
        short_label: 'CC',
        name: 'Credit Card',
        type: 'link',
        icon: 'fa fa-credit-card'
      },

      // {
      //   state: 'fee-table',
      //   short_label: 'FT',
      //   name: 'Fee Table',
      //   type: 'link',
      //   icon: 'fa fa-table'
      // },

      {
        state: 'settings',
        short_label: 'S',
        name: 'Settings',
        type: 'sub',
        icon: 'fa fa-gears',
        children: [
          {
            state: 'fee/table',
            name: 'Fee Table'
          },
          {
            state: 'wallet/address',
            name: 'Admin Wallet Address',
          },
          {
            state: 'cd/interest_rates',
            name: 'CoD Interest Rates',
          },
          {
            state: 'wire/instructions',
            name: 'Wire Transfer',
          },
        ]
      },

      {
        state: 'regenerate/otp',
        short_label: 'RO',
        name: 'Generate OTP',
        type: 'link',
        icon: 'fa fa-gear'
      },
    ]
  }
];

const MENU_ITEMS_ROLE_5 = [
  {
    label: '',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Dashboard',
        type: 'link',
        icon: 'fa fa-home'
      },

      {
        state: 'accounts',
        short_label: 'A',
        name: 'Accounts',
        type: 'sub',
        icon: 'fa fa-bank',
        children: [
          {
            state: 'all',
            name: 'View All',
          },
        ]
      },

      {
        state: 'crypto',
        short_label: 'CA',
        name: 'Crypto Accounts',
        type: 'sub',
        icon: 'fa fa-btc',
        children: [
          {
            state: 'all',
            name: 'View All',
          },
        ]
      },

      {
        state: 'banking/products',
        short_label: 'BP',
        name: 'Banking Products',
        type: 'sub',
        icon: 'fa fa-list',
        children: [
          {
            state: 'dc',
            name: 'Certificate of Deposit'
          },
          {
            state: 'sblc',
            name: 'Standby Letter of Credit'
          },
          {
            state: 'bg',
            name: 'Bank Guarantee'
          },
          {
            state: 'skr',
            name: 'Safe Keeping Receipt'
          },
          {
            state: 'bcc',
            name: 'Bank Certify Checks'
          },
          {
            state: 'pof',
            name: 'Proof of Funds'
          },
          {
            state: 'bf',
            name: 'Block Funds'
          },
          {
            state: 'ktt',
            name: 'Key Tested Telex'
          },
          {
            state: 'swift',
            name: 'Swift'
          },
        ]
      },
    ]
  }
];

const MENU_ITEMS_ROLE_6 = [
  {
    label: '',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Dashboard',
        type: 'link',
        icon: 'fa fa-home'
      },

      {
        state: 'customers',
        short_label: 'C',
        name: 'Customers',
        type: 'sub',
        icon: 'fa fa-users',
        children: [
          {
            state: 'add',
            name: 'Add Personal Profile'
          },
          {
            state: 'business/add',
            name: 'Add Business Profile'
          },
          {
            state: 'list',
            name: 'View All',
          },
        ]
      },

      {
        state: 'accounts',
        short_label: 'A',
        name: 'Accounts',
        type: 'sub',
        icon: 'fa fa-bank',
        children: [
          {
            state: 'add',
            name: 'Add New'
          },
          {
            state: 'all',
            name: 'View All',
          },
          {
            state: 'debit/card',
            name: 'All Debit Cards',
          },
        ]
      },

      {
        state: 'crypto',
        short_label: 'CA',
        name: 'Crypto Accounts',
        type: 'sub',
        icon: 'fa fa-btc',
        children: [
          {
            state: 'add',
            name: 'Add New'
          },
          {
            state: 'all',
            name: 'View All',
          },
        ]
      },

      {
        state: 'banking/products',
        short_label: 'BP',
        name: 'Banking Products',
        type: 'sub',
        icon: 'fa fa-list',
        children: [
          {
            state: 'dc',
            name: 'Certificate of Deposit'
          },
          {
            state: 'sblc',
            name: 'Standby Letter of Credit'
          },
          {
            state: 'bg',
            name: 'Bank Guarantee'
          },
          {
            state: 'skr',
            name: 'Safe Keeping Receipt'
          },
          {
            state: 'bcc',
            name: 'Bank Certify Checks'
          },
          {
            state: 'pof',
            name: 'Proof of Funds'
          },
          {
            state: 'bf',
            name: 'Block Funds'
          },
          {
            state: 'ktt',
            name: 'Key Tested Telex'
          },
          {
            state: 'swift',
            name: 'Swift'
          },
        ]
      },
    ]
  }
];

const MENU_ITEMS_ROLE_7 = [
  {
    label: '',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Dashboard',
        type: 'link',
        icon: 'fa fa-home'
      },

      {
        state: 'accounts',
        short_label: 'A',
        name: 'Accounts',
        type: 'sub',
        icon: 'fa fa-bank',
        children: [
          {
            state: 'all',
            name: 'View All',
          },
          {
            state: 'debit/card',
            name: 'All Debit Cards',
          },
          {
            state: 'hold_balance',
            name: 'Hold Account Balance',
          },
        ]
      },

      {
        state: 'crypto',
        short_label: 'CA',
        name: 'Crypto Accounts',
        type: 'sub',
        icon: 'fa fa-btc',
        children: [
          {
            state: 'all',
            name: 'View All',
          },
          {
            state: 'deposit',
            name: 'Crypto Deposits',
          },
        ]
      },

      {
        state: 'deposits',
        short_label: 'D',
        name: 'Deposits',
        type: 'link',
        icon: 'fa fa-money'
      },

      {
        state: 'funds/transfer',
        short_label: 'FTF',
        name: 'Funds Transfer',
        type: 'sub',
        icon: 'fa fa-link',
        children: [
          {
            state: '',
            name: 'Wire/SEPA'
          },
          {
            state: 'cryptoo',
            name: 'Crypto',
          },
          {
            state: 'c2c',
            name: 'Crypto 2 Crypto',
          },
        ]
      },

      {
        state: 'requests',
        short_label: 'R',
        name: 'Requests',
        type: 'sub',
        icon: 'fa fa-tasks',
        children: [
          {
            state: 'cheque/block',
            name: 'Cheque Block'
          },
          {
            state: 'loan/application',
            name: 'Loan Application',
          },
          {
            state: 'credit/card',
            name: 'Credit Card',
          },
          {
            state: 'travel/notification',
            name: 'Travel Notification',
          },
        ]
      },

      {
        state: 'loan',
        short_label: 'L',
        name: 'Loan',
        type: 'link',
        icon: 'fa fa-money'
      },

      {
        state: 'credit/card',
        short_label: 'CC',
        name: 'Credit Card',
        type: 'link',
        icon: 'fa fa-credit-card'
      },

      {
        state: 'regenerate/otp',
        short_label: 'RO',
        name: 'Generate OTP',
        type: 'link',
        icon: 'fa fa-gear'
      },
    ]
  }
];


@Injectable()
export class MenuItems {

  constructor(private userService: UserService) {}
  getAll(): Menu[] {

    if (+this.userService.role < 5) {
      return MENUITEMS;
    } else if (+this.userService.role === 5) {
      return MENU_ITEMS_ROLE_5;
    } else if (+this.userService.role === 6) {
      return MENU_ITEMS_ROLE_6;
    } else if (+this.userService.role === 7) {
      return MENU_ITEMS_ROLE_7;
    }
    // return MENUITEMS;
  }
}
