import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AdminComponent} from './layout/admin/admin.component';
import {AuthComponent} from './layout/auth/auth.component';
import {AuthGuard} from './services/auth-guard.service';
import {SuperAdminGuardService} from './services/super-admin-guard.service';
import {AdminRolesGuardService} from './services/admin-roles-guard.service';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: '',
        loadChildren: './theme/auth/auth.module#AuthModule',
      },
    ]
  },
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        canActivate: [AdminRolesGuardService],
        loadChildren: './theme/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'users',
        canActivate: [AdminRolesGuardService],
        loadChildren: './theme/users/users.module#UsersModule'
      },
      {
        path: 'customers',
        canActivate: [AdminRolesGuardService],
        loadChildren: './theme/customers/customers.module#CustomersModule'
      },
      {
        path: 'accounts',
        canActivate: [AdminRolesGuardService],
        loadChildren: './theme/accounts/accounts.module#AccountsModule'
      },
      {
        path: 'deposits',
        canActivate: [AdminRolesGuardService],
        loadChildren: './theme/deposits/deposits.module#DepositsModule'
      },
      {
        path: 'requests',
        canActivate: [AdminRolesGuardService],
        loadChildren: './theme/requests/requests.module#RequestsModule'
      },
      {
        path: 'settings/fee/table',
        canActivate: [AdminRolesGuardService],
        loadChildren: './theme/fee/fee.module#FeeModule'
      },
      {
        path: 'settings',
        canActivate: [AdminRolesGuardService],
        loadChildren: './theme/settings/settings.module#SettingsModule'
      },
      {
        path: 'funds/transfer',
        canActivate: [AdminRolesGuardService],
        loadChildren: './theme/funds-transfer/funds-transfer.module#FundsTransferModule'
      },
      {
        path: 'loan',
        canActivate: [AdminRolesGuardService],
        loadChildren: './theme/loan/loan.module#LoanModule'
      },
      {
        path: 'credit/card',
        canActivate: [AdminRolesGuardService],
        loadChildren: './theme/credit-card/credit-card.module#CreditCardModule'
      },
      {
        path: 'banking/products',
        canActivate: [AdminRolesGuardService],
        loadChildren: './theme/banking-products/banking-products.module#BankingProductsModule'
      },
      {
        path: 'crypto',
        canActivate: [AdminRolesGuardService],
        loadChildren: './theme/crypto/crypto.module#CryptoModule'
      },
      {
        path: 'regenerate/otp',
        canActivate: [AdminRolesGuardService],
        loadChildren: './theme/regenerate-otp/regenerate-otp.module#RegenerateOtpModule'
      },
      {
        path: 'admin',
        canActivate: [AdminRolesGuardService],
        loadChildren: './theme/admin/admin.module#AdminModule'
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
